$base-colors: (
	black: #000,
	dark: #999,
	medium: #ddd,
	light: #f9f9f9,
	accent: map-get($settings, accent)
);

$theme-dark: (
	menu-background: map-get($base-colors, black),
	menu-text: map-get($base-colors, light),
	normal: map-get($base-colors, medium)
);

$theme-light: (
	menu-background: map-get($base-colors, light),
	menu-text: map-get($base-colors, black),
	normal: map-get($base-colors, dark)
);

$theme: $theme-light !default;

@if (map-get($settings, theme) == 'dark') {
	$theme: $theme-dark;
}

$colors: map-merge($base-colors, $theme);

$menu-width: map-get($settings, menu-width);

$header-height: map-get($settings, header-height);


// functions
@function color($color) {
    @return map-get($colors, $color);
}


// mixins
@mixin clearfix {
	&:after {
		clear: both;
	}

	&:before,
	&:after {
		display: table;
		content: ' ';
	}
}

@mixin border-box {
	box-sizing: border-box;
}

// Font size for Rem font sizing (with px fallback)
@mixin font-size($sizeValue: 1.6) {
  font-size: $sizeValue + px;
  font-size: ($sizeValue / 10) + rem;
}
