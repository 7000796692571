/* Specific styles for the Anthony Nolan styleguide */

header {
	position: fixed;
	width: 100%;
	z-index: 100;
	background: rgba(255,255,255,0.95);
	border-bottom: 1px solid #ddd;
	h1 {
		float: left;
		margin: 0;
		padding: 25px 20px 0;
		text-transform: uppercase;
		font-family: 'Gotham SSm A', 'Gotham SSm B';
		font-weight: bold;
		line-height: 0.95;
		@media (max-width: 50em) {
			@include font-size(18);
			width: calc(100% - 230px);
			padding: 13px 0 0;
		}
		@media (max-width: 25em) {
			@include font-size(14);
			padding: 10px 0 0;
		}
	}
	.an-logo {
		display: block;
		float: left;
		width: 210px;
		height: auto;
		padding: 10px;
	}
}

// Disable code blocks on colour page as markup is specific for colour chips
.page-colours [data-f-toggle-control="code"] {
	display: none;
}

// Background colour for grid columns
.col::after {
	display: block;
	content: '';
	background: #f1f1f1;
	min-height: 100px;
}
